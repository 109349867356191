<template>
  <base-section
    id="apply-trial-authorization"
    space="56"
  >
    <base-section-heading
      color="grey lighten-2"
      title="开始试用"
    >
      只需要简单的几步，就可以完成您的分析任务
    </base-section-heading>

    <v-container>
      <v-row justify="space-between">
        <v-col
          cols="12"
          md="5"
        >
          <base-license-form title="获取6个月免费试用授权" />
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <base-license-form title="获取正式授权" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>

  export default {
    name: 'SectionApplyTrialAuthorization',
  }
</script>
